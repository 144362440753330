import request from "@/utils/request";  //导入封装请求的js文件

//学校时长分页查询
export function SchoolPageList(data) {
	return request({
		url: '/Analysis/SchoolPageList',
		method: 'post',
		data
	})
}

//学校时长导出
export function SchoolExport(data) {
	return request({
		url: '/Analysis/SchoolExport',
		method: 'post',
		responseType:'blob',
		data
	})
}

//教师时长分页查询
export function TeacherPageList(data) {
	return request({
		url: '/Analysis/TeacherPageList',
		method: 'post',
		data
	})
}

//教师时长导出
export function TeacherExport(data) {
	return request({
		url: '/Analysis/TeacherExport',
		method: 'post',
		responseType:'blob',
		data
	})
}