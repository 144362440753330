<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content" id="middleArea">

            <el-row :gutter="40" class="panel-group">
                <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel">
                        <div class="card-panel-icon-wrapper icon-message">
                            <i class="el-icon-school" style="font-size:3rem"></i>
                        </div>
                        <div class="card-panel-description">
                            <div class="card-panel-text">
                                本学期学校数
                            </div>
                            <div class="card-panel-num">{{ semesterSchoolNum }}</div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel">
                        <div class="card-panel-icon-wrapper icon-people">
                            <i class="el-icon-s-custom" style="font-size:3rem"></i>
                        </div>
                        <div class="card-panel-description">
                            <div class="card-panel-text">
                                本学期教师数
                            </div>
                            <div class="card-panel-num">{{ semesterTeacherNum }}</div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel">
                        <div class="card-panel-icon-wrapper icon-money">
                            <i class="el-icon-s-data" style="font-size:3rem"></i>
                        </div>
                        <div class="card-panel-description">
                            <div class="card-panel-text">
                                本学期时长(小时)
                            </div>
                            <div class="card-panel-num">{{ semesterTeacherDuration }}</div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel">
                        <div class="card-panel-icon-wrapper icon-shopping">
                            <i class="el-icon-user" style="font-size:3rem"></i>
                        </div>
                        <div class="card-panel-description">
                            <div class="card-panel-text">
                                本学期学生数
                            </div>
                            <div class="card-panel-num">{{ semesterStudentNum }}</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-form :inline="true" class="form-search" style="margin-top: 10px;margin-left: 10px;">
                <el-form-item label="学校名称">
                    <el-input v-model="schoolname" placeholder="请输入学校名称"></el-input>
                </el-form-item>
                <el-form-item label="开始日期">
                    <el-date-picker v-model="starttime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束日期">
                    <el-date-picker v-model="endtime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="GetSchoolPageList()" :loading="listLoading">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="DownSchoolExport()" :loading="downLoading">导出</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="pagelist" stripe style="width: 100%" @sort-change="sortChange" size="medium"
                :header-cell-style="{ fontSize: '16px' }">
                <el-table-column header-align="center" type="index" :index="indexMethod" width="55" align="center" />
				<el-table-column prop="schoolName" label="学校名称">
                </el-table-column>
                <!-- <el-table-column prop="schoolCode" label="学校编码" align="center">
                </el-table-column> -->
                <el-table-column prop="teacherNum" label="教师数" align="center">
                </el-table-column>
                <el-table-column prop="studentNum" label="学生数" align="center">
                </el-table-column>
                <el-table-column prop="courseDuration" label="时长(小时)" sortable="custom" align="center">
                </el-table-column>
                <el-table-column prop="strStartTime" label="开始日期" sortable="custom" align="center">
                </el-table-column>
                <el-table-column prop="strEndTime" label="结束日期" sortable="custom" align="center">
                </el-table-column>
            </el-table>
            <div class="flex-acjc" style="margin-top: 10px;">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper"
                    :total=this.total>
                </el-pagination>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>
import {
    Message,
    MessageBox
} from 'element-ui' //导入element-ui组件库
import {
    SchoolPageList,
    SchoolExport
} from '@/api/dataanalysis'
import {
    getInfo
} from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'
import {
    getToken
} from '@/utils/cookies.js'

export default {
    name: 'DataDetail',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            semesterTeacherNum: 0,
            semesterSchoolNum: 0,
            semesterTeacherDuration: 0,
            semesterStudentNum: 0,
            listLoading: false,
            downLoading: false,
            currentPage: 1,//初始页
            pagesize: 20,//每页的数据
            users: {},//用户信息
            total: 0,//总数
            schoolname: '',
            starttime: '',
            endtime: '',
            listQuery: {
                pageIndex: this.currentPage,
                pageSize: this.pagesize,
                schoolname: this.schoolname,
                starttime: this.starttime,
                endtime: this.endtime,
                sortModelOrder: '',
                sortModelField: ''
            },
            pagelist: [],
            mdd: 0,
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //学校时长分页查询
        GetSchoolPageList()
    },
    methods: {
		indexMethod(index) {
		    // this.pageNum当前页码
		    // this.pageSize 每页条数
		    return (this.currentPage - 1) * this.pagesize + index + 1;
		},
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //学校时长分页查询
        GetSchoolPageList() {
            var token = getToken();
            if (token) {
                this.listLoading = true;
                this.listQuery.pageIndex = this.currentPage;
                this.listQuery.pageSize = this.pagesize;
                this.listQuery.schoolname = this.schoolname;
                this.listQuery.starttime = this.starttime;
                this.listQuery.endtime = this.endtime;
                SchoolPageList(this.listQuery).then(response => {
                    if (response.status == 200) {
                        this.total = response.data.total;
                        this.pagelist = response.data.list;
                        this.semesterTeacherNum = response.data.semester.semesterTeacherNum;
                        this.semesterSchoolNum = response.data.semester.semesterSchoolNum;
                        this.semesterTeacherDuration = response.data.semester.semesterTeacherDuration;
                        this.semesterStudentNum = response.data.semester.semesterStudentNum;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 500)
                })
            } else {
                MessageBox.alert("必须登录才可以查看！", "提示", {
                    confirmButtonText: '确定',
                    type: 'info'
                })
            }
        },
        //导出
        DownSchoolExport() {
            var token = getToken();
            if (token) {
                this.downLoading = true
                this.listQuery.schoolname = this.schoolname;
                this.listQuery.starttime = this.starttime;
                this.listQuery.endtime = this.endtime;
                SchoolExport(this.listQuery).then(response => {
                    let download_url = window.URL.createObjectURL(response); //数据流作为下载的源
                    let eleLink = document.createElement('a');  //创建一个a标签
                    eleLink.href = download_url.replace(/^http/, 'https');//下载的文件连接，实际就是下载的数据流 
                    eleLink.download = "学校时长";//下载的文件名称
                    document.body.appendChild(eleLink);
                    eleLink.click()//模拟下载文件
                    window.URL.revokeObjectURL(download_url);
                    document.body.removeChild(eleLink);
                    window.URL.revokeObjectURL(download_url);
                    setTimeout(() => {
                        this.downLoading = false;
                    }, 500)
                })
            } else {
                MessageBox.alert("必须登录才可以查看！", "提示", {
                    confirmButtonText: '确定',
                    type: 'info'
                })
            }
        },
        //初始页currentPage、初始每页数据数pagesize和数据data
        handleSizeChange(size) {
            this.pagesize = size;
            this.GetSchoolPageList();
        },
        //点击第几页
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.GetSchoolPageList();
        },
        //排序
        sortChange(column) {
            //判断排序规则
            if (column.order) {
                if (column.order === 'descending') {
                    this.listQuery.sortModelOrder = 'desc'
                } else if (column.order === 'ascending') {
                    this.listQuery.sortModelOrder = 'asc'
                }
                this.listQuery.sortModelField = column.prop
            } else {

                this.listQuery.sortModelOrder = ''
                this.listQuery.sortModelField = ''
            }
            //调用查询list接口
            this.GetSchoolPageList();
        },
    },
    created() {
        Promise.all([this.userInfo()]).then(() => {
            this.GetSchoolPageList();
        })
    },
    mounted() {
        this.$refs.nav.mdd = 7;
        localStorage.setItem("mdd", 7);
        this.mdd = 7;
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;
    }
}
</style>
<style lang="less">
// 手机
@media(max-width:768px) {
    .news {
        .news-content {
            .teaNews {
                flex-direction: column;

                .newsLeft,
                .newsRight {
                    width: 100% !important;
                }
            }

            .teaCourse .box .row {
                width: 49% !important;
            }
        }
    }
}

//平板端
@media(min-width:768px) {
    .news {
        .news-content {
            .teaNews {
                flex-direction: column;

                .newsLeft,
                .newsRight {
                    width: 100% !important;
                }
            }

            .teaCourse .box .row {
                width: 49% !important;
            }
        }
    }
}

//小屏
@media(min-width:992px) {
    .news {
        .news-content {
            .teaNews {
                flex-direction: row;

                .newsLeft,
                .newsRight {
                    //width: 49% !important;
                    width: 100% !important;
                }
            }

            .teaCourse .box .row {
                width: 25% !important;

                .courseImg {
                    height: 120px !important;
                }
            }

        }
    }
}

//大屏
@media(min-width:1300px) {
    .news {
        .news-content {
            .teaCourse .box .row {
                .courseImg {
                    height: 150px !important;
                }
            }
        }
    }
}

@media(min-width:1600px) {
    .news {
        .news-content {
            .teaCourse .box .row {
                .courseImg {
                    height: 180px !important;
                }
            }
        }
    }
}
</style>

<style lang="less">
.panel-group {
    margin-top: 18px;

    .card-panel-col {
        margin-bottom: 32px;
    }

    .card-panel {
        height: 108px;
        cursor: pointer;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        color: #666;
        background: #fff;
        box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
        border-color: rgba(0, 0, 0, .05);

        &:hover {
            .card-panel-icon-wrapper {
                color: #fff;
            }

            .icon-people {
                background: #40c9c6;
            }

            .icon-message {
                background: #36a3f7;
            }

            .icon-money {
                background: #f4516c;
            }

            .icon-shopping {
                background: #34bfa3
            }
        }

        .icon-people {
            color: #40c9c6;
        }

        .icon-message {
            color: #36a3f7;
        }

        .icon-money {
            color: #f4516c;
        }

        .icon-shopping {
            color: #34bfa3
        }

        .card-panel-icon-wrapper {
            float: left;
            margin: 14px 0 0 14px;
            padding: 16px;
            transition: all 0.38s ease-out;
            border-radius: 6px;
        }

        .card-panel-icon {
            float: left;
            font-size: 48px;
        }

        .card-panel-description {
            float: right;
            font-weight: bold;
            margin: 16px;
            margin-left: 0px;

            .card-panel-text {
                line-height: 18px;
                color: rgba(0, 0, 0, 0.45);
                font-size: 0.8rem;
                margin-bottom: 12px;
            }

            .card-panel-num {
                font-size: 0.8rem;
            }
        }
    }
}

@media (max-width:550px) {
    .card-panel-description {
        display: none;
    }

    .card-panel-icon-wrapper {
        float: none !important;
        width: 100%;
        height: 100%;
        margin: 0 !important;

        .svg-icon {
            display: block;
            margin: 14px auto !important;
            float: none !important;
        }
    }
}
</style>